export const URL = {
  // dev

  // API_BASE_URL: "http://localhost:5600",
  API_BASE_URL: "https://classicadda.in:3002",

  // apipublic

  login_admin: "/publicApi/signinAdmin",

  users: "/adminApi/getUserList",
  user_inactive: "/adminApi/getUserListActive",
  addFoundByAdmin: "/adminApi/addFoundByAdmin",
  getUserListseacrch: "/adminApi/getUserListseacrch",
  getBettalList: "/adminApi/getBettalList",
  deleteByAdminBettal: "/adminApi/deleteByAdminBettal",
  getBettalListAdmin: "/adminApi/getBettalListAdmin",
  getwithdrwarlAdminList: "/adminApi/getwithdrwarlAdminList",
  completeWithdrawalReq: "/adminApi/completeWithdrawalReq",
  getkycpending: "/adminApi/getkycpending",
  getTransactionList: "/adminApi/getTransactionList",
  getProfileDetails: "/adminApi/getProfileDetails",
  getbDetails: "/adminApi/getbDetails",
  dashboard: "/adminApi/dashboard",
  dashboardChart: "/adminApi/dashboardChart",
  getsetting: "/adminApi/getsetting",
  createSetting: "/adminApi/createSetting",
  getsearchTransactionList: "/adminApi/getsearchTransactionList",

  verifykeyByAdmin: "/adminApi/verifykeyByAdmin",
  submitwinnerAdmin: "/adminApi/submitwinnerAdmin",
  createaccountList: "/adminApi/createaccountList",
  getaccountList: "/adminApi/getaccountList",
  deleteaccountList: "/adminApi/deleteaccountList",
  acctiveaccountlist: "/adminApi/acctiveaccountlist",
  addfundreqestListAdmin: "/adminApi/addfundreqestListAdmin",
  addfundreqestUpdateAdmin: "/adminApi/addfundreqestUpdateAdmin",
  createmessage: "/adminApi/createmessage",
  messageList: "/adminApi/messageList",
  messagedelete: "/adminApi/messagedelete",
  submitwinnerAdmincencel: "/adminApi/submitwinnerAdmincencel",
  submitwinnerAdmincencelCron: "/adminApi/submitwinnerAdmincencelCron",
  user_contact: "/api/user_contact",
  all_category: "/api/all_category",
  all_category_main: "/api/all_category_main",
  delete_category: "/api/delete_category",
  update_category: "/api/update_category",
  create_quiz_category: "/api/create_quiz_category",
  quiz_list: "/api/quiz_list",
  create_quiz: "/api/create_quiz",
  delete_quiz: "/api/delete_quiz",
  update_quiz: "/api/update_quiz",
  questions_list: "/api/questions_list",
  delete_question: "/api/delete_question",
  update_question: "/api/update_question",
  create_quiz_question: "/api/create_quiz_question",
  getBettalListAdminFilter: "/adminApi/getBettalListAdminFilter",
  user_allcontact: "/api/user_allcontact",

  quiz_active: "/api/quiz_active",

  createcategories: "/apiVendor/createcategories",
  getCategory: "/publicApi/getCategory",
  getsubsubcate: "/publicApi/getsubsubcate",
  getsubsubcateHome: "/publicApi/getsubsubcateHome",
  signupSeller: "/publicApi/signupSeller",
  otpVerify: "/publicApi/otpVerify",
  productDetails: "/publicApi/productDetails",
  getsellerProductListPublice: "/publicApi/getsellerProductListPublice",
  gettagsProductListPublice: "/publicApi/gettagsProductListPublice",
  getsellerProductListPublicefilCate:
    "/publicApi/getsellerProductListPublicefilCate",
  getsellerProductListPublicebycategory:
    "/publicApi/getsellerProductListPublicebycategory",
  getsellerprofile: "/publicApi/getsellerprofile",
  whatsappinquiresubmit: "/publicApi/whatsappinquiresubmit",
  getsubsubCategoryList: "/publicApi/getsubsubCategoryList",
  createSubscription: "/publicApi/createSubscription",
  getPages: "/publicApi/getPagesPu",
  emailenquirysubmit: "/publicApi/emailenquirysubmit",
  pageDetails: "/publicApi/pageDetails",
  searchProductpub: "/publicApi/searchProductpub",
  sendOtpSeller: "/publicApi/sendOtpSeller",
  otpVerifyseller: "/publicApi/otpVerifyseller",
  signinSellereSecurite: "/publicApi/signinSellereSecurite",
  footerPagepageListetPu: "/publicApi/footerPagepageListetPu",
  productclick: "/publicApi/productclick",
  getHomePro: "/publicApi/getHomePro",
  getSellerListPublic: "/publicApi/getSellerListPublic",
  getsellerProductListPublicebymaincategory2:
    "/publicApi/getsellerProductListPublicebymaincategory2",

  // apiAdmin
  signinSeller: "/publicApi/signinSeller",
  getsellerProductList: "/sellerApi/getsellerProductList",
  createSellerProduct: "/sellerApi/createSellerProduct",
  searchSellerProduct: "/sellerApi/searchSellerProduct",
  deleteSellerProduct: "/sellerApi/deleteSellerProduct",
  createBussnissPro: "/sellerApi/createBussnissPro",
  getBussProfile: "/sellerApi/getBussProfile",
  updateWhatsaapNumber: "/sellerApi/updateWhatsaapNumber",
  verifyWhatsapp: "/sellerApi/verifyWhatsapp",
  updateBussLogo: "/sellerApi/updateBussLogo",
  updateBussProfile: "/sellerApi/updateBussProfile",
  getPackageList: "/sellerApi/getPackageList",
  createPurchase: "/sellerApi/createPurchase",
  getsellerTransaction: "/sellerApi/getsellerTransaction",
  getprofile: "/sellerApi/getprofile",
  updatesellerProduct: "/sellerApi/updatesellerProduct",
  getSellerTicketList: "/sellerApi/getSellerTicketList",
  createSellerTickets: "/sellerApi/createSellerTickets",
  getmessageSupportList: "/sellerApi/getmessageSupportList",
  createMessage: "/sellerApi/createMessage",
  deleteacountByseller: "/sellerApi/deleteacountByseller",
  copyProduct: "/sellerApi/copyProduct",
  getInvoice: "/sellerApi/getInvoice",
  getEmailenquirySeller: "/sellerApi/getEmailenquirySeller",
  getsellerWhatsappinquire: "/sellerApi/getsellerWhatsappinquire",
  searchtags: "/sellerApi/searchtags",
  passwordChange: "/sellerApi/passwordChange",
  topViewnewSeller: "/sellerApi/topViewnewSeller",
  sellerDashboardState: "/sellerApi/sellerDashboardState",
  getExpireDate: "/sellerApi/getExpireDate",
  getsliderList: "/publicApi/getsliderList2",
  getBlogListPub: "/publicApi/getBlogListPub",
  getBlogDetails: "/publicApi/getBlogDetails",
  gethomePop: "/publicApi/gethomePop",
  getlistfaqs: "/publicApi/getlistfaqs",
  getInvoceDetails: "/publicApi/getInvoceDetails",
};
